.expertise-svg-container{
    position: relative;
    width:100%;
    height:fit-content;
    top:10vh;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 900px) {
        top:30vh;

        .expertise-center{
            height: 50px;
            width: 50px;
        }
        .expertise-logo{
            width:45px;
        }
    }
}

.expertise-center{
    width: 90px;
    height: 90px;

    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%;
    align-self: center;
    flex-direction: row;
    display:flex;

    position: absolute;
    top:22px;
    bottom:0;
}
.expertise-logo{
    width:70px;
    margin: 2px;
}
.expertise-text{
    color: rgb(1, 41, 120);
    font-size: min(max(18px, 7vw),35px);
    font-weight: bold;
}

.section-heading.expertise{
    margin-bottom: 0px;
    font-size: min(max(50px, 8vw),70px);
}

.chip.expertise-img{
    height: 10%;
    border-radius: 25px;
    position:absolute;
    padding: 0;
    overflow: hidden;
}
.chip-img{
    height:100%;
}
#chip-img-1{
    top: 12%;
}
#chip-img-2{
    top: 30%;
}
#chip-img-3{
    top: 49%;
}
#chip-img-4{
    top: 67%;
}
#chip-img-5{
    top: 86%;
}

.chip.expertise-text{
    height: 10%;
    border-radius: 25px;
    position:absolute;
    right:20px;
}
.chip.expertise-text{
    font-size:1vw;
}
