.horizontal-scroll-section {
    position: relative;
    height: 400vh;
}

.horizontal-scroll-view {
    position: sticky;
    top:10px;
    width: 100vw;
    height: 100vh;
    display:flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
}

.horizontal-scroll-container {
    display: flex;
    flex-direction: row;
    height: fit-content;
    justify-self: center;
}