/*List Item*/
.list-item{
    display: flex;
    flex-direction: column;
    width:100%;
    min-height:550px;
    border-radius: 15px;
    transition: all 0.5s ease;
    background-color: white;
    box-shadow: 0px 10px 10px 0px rgba(100, 100, 100, 0.1);
    margin:30px 5px 30px 5px;
    border: 2px;
    overflow: hidden;
}
.list-image{
    width: 100%;
    max-height:300px;
    border-radius: 15px;
    transition: all 0.5s ease;
}
.list-name{
    color: rgb(0, 23, 150);
    font-size: 40px;
    font-weight: 400;
    margin: 15px 5px 10px 5px;
    line-height: 115%;
}

.list-desc{
    color: rgb(71, 71, 71);
    font-size: 20px;
    font-weight: 400;
    margin: 8px 6px 8px 6px;
    white-space: pre-line;
    display: -webkit-box;
    -webkit-line-clamp: 7; /* Limit the number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.list-desc.expanded{
    -webkit-line-clamp: unset;
}
.list-item:hover{
    box-shadow: 0px 19px 20px 0px rgba(100, 100, 100, 0.4);

    .list-image{
        transform: scale(1.1);
    }
}


.w-p-link{
    color: black;
    font-size: 22px;
    font-weight: 600;
    margin: 20px 6px 1px 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
}