.home{
    display: grid;
    position: absolute;
    top: 0;
    width:100vw;
    flex-direction: column;

    background-color: black;
}


.highlight{
    background: linear-gradient( 45deg, #78b5ff, #a8bdea);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
    font-weight: bold;
    
    font-size: 110%;
}
.highlight.light{
    background: linear-gradient( 45deg, #0065e0, #476096);
    -webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
    font-weight: bold;
    
    font-size: 110%;
}

.chip{
    width:fit-content;
    padding:10px;
    justify-content: center;
    align-items: center;
    background-color: white;
    min-width: 70px;
    display: flex;
    box-shadow: 0px 5px 5px 0px rgba(100, 100, 100, 0.4);
    justify-self: center;
}


/*****************************************************
* Hero Section
******************************************************/
.hero-section{
    display: flex;
    text-align: center;
    justify-content: center;
    width:100vw;
    height: 100vh;
    flex-direction: column;
}

.hero-text{
    font-size: calc(min(max(80px, 10vw),140px) - 20px);
    color: rgb(246, 240, 240);
    font-weight: 500;
}
.hero-text-main{
    font-size: min(max(80px, 10vw),150px);
    color: white;
    margin-top: 1px;
    
    background: linear-gradient(90deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 300% 300%;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
    animation: gradient 7s ease infinite;

    font-weight: 700;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/*****************************************************
*Home Navigator
******************************************************/
.nav-home{
    display: flex;
    align-items: center;
    width: 80vw;
    height:55px;
    flex-direction: row;
    background-color: rgb(61, 61, 61);
    position: sticky;
    top:5px;
    justify-self: center;
    border-radius: 30px;

    box-shadow: 0px 19px 20px 0px rgba(100, 100, 100, 0.2);
    z-index: 20;
    overflow-y: hidden;
    overflow: hidden;

    @media screen and (max-width: 800px) {width: 95vw;}

    @media screen and (max-width: 530px) {display: none;}

}
.home-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width:100%;
}
.home-item{
    color: white;
    font-size: 20px;
    font-weight: 400;
    text-transform: capitalize;
}
.home-item.active ::before{
    width:100%;
}




/******************************************************
*Sections
*******************************************************/
.section{
    display: flex;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    padding: 10px;

    max-width: 1600px;
    justify-self: center;

    margin: 50px 0px 50px 0px;
}
.section.full-width{
    max-width: 100vw;
}
.section-heading{
    color: white;
    margin-top: 50px;
    margin-bottom: 40px;
    text-align: center;

    font-size: min(max(60px, 10vw),80px);
    line-height: 107%;
    letter-spacing: -0.58px;
}

/*Service Item*/
.service-item{
    display: flex;
    flex-direction: column;
    width:450px;
    height:620px;
    border-radius: 15px;
    transition: all 0.5s ease;
    overflow: hidden;
    background-color: #212121;
    box-shadow: 0px 19px 20px 0px rgba(100, 100, 100, 0.2);
    margin:10px;
}
.service-image{
    width: 100%;
    height:45%;
    border-radius: 15px;
    transition: all 0.5s ease;
}
.service-name{
    color: white;
    font-size: 30px;
    font-weight: 400;
    margin: 15px 5px 10px 5px;
    line-height: 107%;
}
.service-desc{
    color: rgb(238, 238, 238);
    font-size: 18px;
    font-weight: 400;
    margin: 8px 6px 8px 6px;
    white-space: pre-line;
    line-height: 130%;
}
.read-more{
    color:rgb(184, 235, 255);
    display: flex;
    margin:5px;
    justify-content: center;
}
.read-more-text{
    display: flex;
    align-items: center;
    padding: 2px;
    font-size: 20px;;
}

.service-item:hover{
    background-color: rgb(255, 176, 48);

    .service-image{
        transform: scale(1.1);
    }
    p{
        color: black;
    }
}



/* Achievement */

/*Graph*/
.graph{
    width: 99%;
    height: 60vh;
    align-self: center;
    max-width: 1600px;
}
.graph-number{
    font-size: min(max(60px, 10vw),90px);
    margin-top: 1px;
    
    color: white;
    font-family: 'Google Sans',sans-serif;
    width:100%;

    text-align: right;

    background: linear-gradient( 45deg, #4b97f3 60%, #a8bdea);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
    font-weight: bold;
}
.graph-text{
    position:absolute;
    margin:10px;
    font-size: min(max(13px, 4vw),35px);
    color: white;
    width:50%;
    max-width:700px;
    right:0;
    top:53vh;
}

/*Grid*/
.achievement-grid{
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    width:100%;
    align-items: center;
    max-width:1300px;
    align-self: center;

    @media screen and (max-width: 750px) {
        grid-template-columns: repeat(1, 1fr);
        max-width:500px;
    }
}
.achievement-heading{
    color: white;
    position: absolute;
    top:40%;
    font-size: 1.5rem;
    text-align: center;
}
.achievement-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.achievement-svg{
    width: 80%;
}
.svg-polygon{
    rx:10;
    transition: all 1s ease-in-out;
}
.achievement-svg:hover .svg-polygon{
    rx: 1;
}
.svg-polygon.inverse{
    rx: 1;
}
.achievement-svg:hover .svg-polygon.inverse{
    rx: 10;
}


.chip.achievement{
    height:40px;
    border-radius: 20px;
}
.chip.achievement p{
    font:'Google Sans',sans-serif;
    font-size: 23px;
    color:rgb(1, 41, 120);
    font-weight: bold;
}



/*Projects*/
.projects-table{
    width:100%;
    border-radius: 20px;
    border-collapse: collapse;
}
.projects-table th{
    color:rgb(188, 216, 255);
    font-size:min(max(18px, 2.5vw),30px);
}
.projects-table td{
    color:white;
    font-size:min(max(16px, 2vw),28px);
    padding:10px;
    align-items: start;
    vertical-align: top;
    list-style-position: inside;
}
.projects-table td + td { border-left:1px solid rgb(146, 146, 146); }
.projects-table th + th { border-left:1px solid rgb(146, 146, 146); }
.project-list{
    align-items: center;
}

.chip.project{
    align-self: center;
    height:50px;
    border-radius: 25px;
    margin: 10px;
}
.chip.project p{
    font:'Google Sans',sans-serif;
    font-size: 25px;
    color:rgb(1, 41, 120);
}
.chip .material-symbols-outlined{
    color:rgb(1, 41, 120);
    margin:5px;
}


/*Contact*/
.contact-container{
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 20px;
    width:100%;
    border-radius: 20px;
    overflow: hidden;
    background-color: #2e2e2e;

    @media screen and (max-width: 800px) {
        grid-template-columns: 1fr;
    }
}
.contacts{
    display: flex;
    flex-direction: column;
    padding:10px;
}
.contact-item{
    display: flex;
    flex-direction: row;
    margin: 20px 5px 20px 5px;
    justify-content: center;
}
.contact-item img{
    width:50px;
    height:50px;
    border-radius: 50%;
    margin-top: 5px;
    margin-right: 10px;
}
.contact-name{
    font-size: 40px !important;
    margin: 0;
    padding:0;
}
.contact-text-container p{
    color:white;
    font-size: 18px;
}

.map{
    width: 100%;
    height: 100%;
    min-height: 500px;
}

/*Products*/

.products-container{
    display: grid;    
    width:100%;
    margin-bottom: 20px;
}
.product-header{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.products-container.two-column{
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}
.products-container.three-column{
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
}
@media screen and (max-width: 750px) {
    .products-container.two-column{grid-template-columns: 1fr;}
    .products-container.three-column{grid-template-columns: 1fr;}
}

.product-item{
    width: 100%;
    min-height:150px;
    background-color: #2e2e2e;
    border-radius: 20px;
    padding: 15px;
}
.product-image{
    width:60px;
    height:60px;
    border-radius: 9px;
}
