.client {
    height:100px;
}

.parallax {
  overflow: hidden;
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  background-color: white;
}

.parallax .scroller {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 64px;
  display: flex;
  gap:40px;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  color:white
}