* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins';
}

body{
  background-color: #f6feff;
  overflow-x: hidden;
}

a{
  position: relative;
  text-decoration: none;
  color: white; /* Or any desired color */
  width:fit-content;
}
a::before {
  content: "";
  position: absolute;
  bottom: -3px; /* Adjust for desired line position */
  left: 0;
  width: 0%;
  height: 2px; /* Adjust for desired line thickness */
  background-color: white; /* Match link color */
  transition: width 0.3s ease-in-out; /* Adjust duration and timing as needed */
}
a:hover::before {
  width: 100%;
}

/*Scroll Bar*/
::-webkit-scrollbar{
  width: 10px;
  margin: 10px;
  background-color: black;
}
::-webkit-scrollbar-thumb{
  background: #c5c5c5;
  border-radius: 5px;
  margin: 2px;
}