.logo{
    background-color: #fff;
    border-radius: 50%;
}
.logo.navbar{
    width:60px;
    height:60px;
    margin:2px;
    padding:5px;
    background-color: #fff;
}
.logo.footer{
    padding:5px;
}
/********************************************
*Navbar
*********************************************/

.navbar {
    background: rgba(0, 0, 0, 1);
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 10;
}
.navbar-container {
    display: flex;
    align-items: center;
    height: 60px;
    width:100%;
}
.nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 40px;
    list-style: none;
    text-align: center;
    position:absolute;
    right:50px;
}
.nav-item {
    align-items: center;
    justify-content: center;
    padding : 5px;
    border-radius: 5px;
}
.nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}
.menu-icon {
    display: none;
    color: #fff;
    margin-bottom: 10px;
}

@media screen and (max-width: 900px) {
    
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 93vh;
        position: absolute;
        top: 60px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        justify-content: center;
        
    }

    .nav-menu.active {
        background: #242222;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 10;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: wrap;
        display: table;
    }
    .nav-item{
        align-self:center
    }


    .menu-icon {
        display: block;
        position: absolute;
        top: -15px;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
}


/*************************************************
*Footer
**************************************************/
#footer{
    background-color: #171717;
    margin-bottom: 0;
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    padding: 20px;
    z-index:10;


    @media screen and (max-width: 800px) {
        grid-template-columns: repeat(1, 1fr);
    }
    
}

.footer-ipr{
    display: flex;
    flex-direction: column;
}
.footer-ipr p{
    color:white;
    font-size:25px;
}

.footer-contact{
    display: flex;
    flex-direction: column;
}
.footer-contact-heading{
    font-size: 25px !important;
    margin : 5px 0 10px 0 !important;
}
.footer-contact p{
    color:white;
    font-size:20px;
    margin: 5px;
    width:wrap-content;
}